import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"



const IndexPage = () => {
  let myIndex = 0;
  const showNextSlide = () => {
    let i;
    let x = document.getElementsByClassName("mySlides");
    for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
        x[i].style.visibility = "hidden";
    }
    myIndex++;
    if (myIndex > x.length) {
        myIndex = 1;
    }
    x[myIndex - 1].style.display = "block";
    x[myIndex - 1].style.visibility = "visible";
  };

  React.useEffect(() => {
    showNextSlide()
    const i = setInterval(showNextSlide, 4000);
    return () => clearInterval(i)
  }, [])

  return (
    <Layout>
    <Seo title="Home" />
    <div>
    <h1 className="notice">Website Coming Soon</h1>
    <StaticImage
      src="../images/baklava.jpg"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Tray of baklava"
      className="mySlides"
    />
    <StaticImage
      src="../images/layers-baklava.jpg"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Layers of pistachio baklava"
      className="mySlides"
    />
    <StaticImage
      src="../images/turkish-delight.jpg"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Rose, pistachio and vanilla Turkish delight"
      className="mySlides"
    />

    <StaticImage
      src="../images/crunchy-baklava.jpg"
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="Crunchy pistachio baklava"
      className="mySlides"
    />
    <h2 className="overline">Shop 3/41-47 Spencer St, Fairfield NSW 2165</h2>
    <h2 className="overline">
      M: <a href="tel:61490346043">+61 490 346 043</a> | <a href="mailto:info@alshakarchi.com.au">info@alshakarchi.com.au</a>
    </h2>
    </div>
  </Layout>
  );
}


export default IndexPage;
